type Data = Array<{
    key: string;
    title: string;
    to: string;
}>;

const data: Data = [
    {
        key: "koffia2023",
        title: "KOFFIA 2023",
        to: "/history/2023",
    },
    {
        key: "koffia2022",
        title: "KOFFIA 2022",
        to: "/history/2022",
    },
    {
        key: "koffia2021",
        title: "KOFFIA 2021",
        to: "/history/2021",
    },
    {
        key: "koffia2020",
        title: "KOFFIA 2020",
        to: "/history/2020",
    },
    {
        key: "koffia2019",
        title: "KOFFIA 2019",
        to: "/history/2019",
    },
    {
        key: "koffia2018",
        title: "KOFFIA 2018",
        to: "/history/2018",
    },
    {
        key: "koffia2017",
        title: "KOFFIA 2017",
        to: "/history/2017",
    },
    {
        key: "koffia2016",
        title: "KOFFIA 2016",
        to: "/history/2016",
    },
    {
        key: "koffia2015",
        title: "KOFFIA 2015",
        to: "/history/2015",
    },
    {
        key: "koffia2014",
        title: "KOFFIA 2014",
        to: "/history/2014",
    },
    {
        key: "koffia2013",
        title: "KOFFIA 2013",
        to: "/history/2013",
    },
    {
        key: "koffia2012",
        title: "KOFFIA 2012",
        to: "/history/2012",
    },
    {
        key: "koffia2011",
        title: "KOFFIA 2011",
        to: "/history/2011",
    },
    {
        key: "koffia2010",
        title: "KOFFIA 2010",
        to: "/history/2010",
    },
];

export default data;
